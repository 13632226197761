import React from 'react';
import './style.css';

const Agreement: React.FC = () => {
  return (
    <div className="agreement-container">
      <div className="agreement-content">
        <h1>软件使用协议</h1>
        
        <p>
          本协议是用户（以下简称"您"）与北京紫源科技有限公司（以下简称"我们"）之间就王导导关键字识别助手软件（以下简称"本软件"）使用等相关事项所订立的有效合约。您通过下载、安装、使用等行为表明您已充分阅读、理解并同意接受本协议的全部条款。
        </p>

        <p>
          您理解并同意，我们会基于法律法规要求及经营需要，适时对本协议进行修改。修改后的协议条款将在本软件内公示，若您在协议修改后继续使用本软件，则视为您接受修改后的协议。如您不同意相关修改，应立即停止使用本软件。
        </p>

        <section>
          <h2>1. 软件功能说明</h2>
          <p>本软件主要提供以下功能：</p>
          <ul>
            <li>页面关键字智能识别</li>
            <li>识别内容快速复制</li>
            <li>关键字及识别范围自定义设置</li>
            <li>操作结果即时提示</li>
          </ul>
        </section>

        <section>
          <h2>2. 使用规范</h2>
          <p>您在使用本软件时应当遵守以下规范：</p>
          <ul>
            <li>遵守中华人民共和国相关法律法规</li>
            <li>不得利用本软件从事任何违法违规活动</li>
            <li>不得干扰本软件的正常运行</li>
            <li>不得对本软件进行反向工程、反编译或破解</li>
            <li>不得将本软件用于未经授权的商业用途</li>
          </ul>
        </section>

        <section>
          <h2>3. 用户隐私保护</h2>
          <p>
            我们高度重视用户隐私保护，承诺对您使用本软件过程中产生的个人信息、关键字设置等数据进行严格保密。具体隐私保护措施请参见《隐私政策》。
          </p>
        </section>

        <section>
          <h2>4. 知识产权声明</h2>
          <p>
            本软件的所有权、运营权和知识产权均属于我们所有。未经我们书面许可，任何人不得擅自使用、复制、传播、修改、链接、转载相关内容。
          </p>
        </section>

        <p>
          如您对本协议或本软件的使用有任何问题，可通过以下方式联系我们：<br />
          邮箱：wuning701122@163.com
        </p>
      </div>
    </div>
  );
};

export default Agreement; 