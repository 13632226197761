import React from 'react';
import './style.css';

const Privacy: React.FC = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>王导导关键字识别助手的隐私政策</h1>
        <p className="update-date">更新日期：2024年3月1日</p>
        
        <section>
          <h2>1. 引言</h2>
          <p>
            感谢您选择使用由北京紫源科技有限公司（以下简称"我们"）开发、运营的王导导关键字识别助手。我们非常重视您的个人信息和隐私保护。
          </p>
        </section>

        <section>
          <h2>2. 我们收集的信息</h2>
          <h3>2.1 您主动提供的信息</h3>
          <p>为了提供基础服务，我们可能会收集以下信息：</p>
          <ul>
            <li>设备信息：设备型号、操作系统版本、设备设置等</li>
            <li>使用数据：您设置的关键字信息、识别范围等配置信息</li>
            <li>操作记录：应用的使用频率、功能使用记录等</li>
            <li>性能数据：应用运行状态、崩溃记录等技术数据</li>
          </ul>
        </section>

        <section>
          <h2>3. 信息的使用</h2>
          <p>我们收集的信息将用于：</p>
          <ul>
            <li>提供关键字识别和复制服务</li>
            <li>保存您的个性化设置</li>
            <li>优化产品功能和用户体验</li>
            <li>提供技术支持和问题排查</li>
          </ul>
        </section>

        <section>
          <h2>4. 信息的保护</h2>
          <p>
            我们采取业界标准的安全措施保护您的信息安全，包括但不限于：数据加密存储、访问控制、安全审计等。
            所有数据均存储在中国境内的服务器上，并严格遵守相关法律法规的要求。
          </p>
        </section>

        <section>
          <h2>5. 您的权利</h2>
          <p>您对您的个人信息享有以下权利：</p>
          <ul>
            <li>访问、更正您的个人信息</li>
            <li>删除您设置的关键字信息</li>
            <li>更改识别范围设置</li>
            <li>注销账号</li>
          </ul>
        </section>

        <section>
          <h2>6. 联系我们</h2>
          <p>
            如果您对本隐私政策有任何疑问、意见或建议，请通过以下方式与我们联系：<br />
            邮箱：wuning701122@163.com
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
