import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Hero from './components/Hero';
import Header from './components/Header';
import './App.css';
import Privacy from './components/Privacy';
import Agreement from './components/Agreement';

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FF7F41',
        },
      }}
    >
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/agreement" element={<Agreement />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App; 