import React from 'react';
import {Button, message} from 'antd';
import './style.css';

const newsItems = [
    {
        date: '智能识别',
        title: '一键识别 快速复制',
        image: require('../../assets/images/Screenshot_20241209_163840.jpg')
    },
    {
        date: '个性化设置',
        title: '自定义关键字 灵活配置',
        image: require('../../assets/images/Screenshot_20241209_161933.jpg')
    }
];

const aboutContent = {
    title: "关于我们",
    description: "王导导是一款智能关键字识别助手，致力于帮助用户快速识别和复制页面上的关键信息，提高工作效率。",
    features: [
        "智能识别：快速识别页面上的关键字信息",
        "一键复制：识别成功后可直接复制，操作便捷",
        "复制提示：复制成功后及时反馈提示信息",
        "个性化配置：支持自定义关键字及识别范围设置"
    ]
};

const scrollImages = [
    {
        src: require('../../assets/images/Screenshot_20241209_163840.jpg'),
        alt: '功能展示1'
    },
    {
        src: require('../../assets/images/Screenshot_20241209_163845.jpg'),
        alt: '功能展示2'
    },
    {
        src: require('../../assets/images/Screenshot_20241209_163852.jpg'),
        alt: '功能展示3'
    }, {
        src: require('../../assets/images/Screenshot_20241209_163858.jpg'),
        alt: '功能展示4'
    },
];

const Hero: React.FC = () => {
    return (
        <div className="hero">
            <div className="hero-content">
                <h1>MORE THAN A JOURNEY</h1>
                <h2>王导导 您的贴心识别助手</h2>
                <Button
                    type="primary"
                    size="large"
                    onClick={() => message.warning({content: "应用上架中,敬请期待"})}
                >
                    即刻出发
                </Button>


                <div className="about-section">
                    <h2>{aboutContent.title}</h2>
                    <p className="about-description">{aboutContent.description}</p>
                    <div className="news-section">
                        {newsItems.map((item, index) => (
                            <div key={index} className="news-item">
                                <div className="news-image">
                                    <img src={item.image} alt={item.title}/>
                                </div>
                                <span className="news-date">{item.date}</span>
                                <h3 className="news-title">{item.title}</h3>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="about-section">
                    <h2>APP运行效果图</h2>
                    <div className="scroll-section">
                        <div className="scroll-container">
                            {[...scrollImages, ...scrollImages].map((image, index) => (
                                <div key={index} className="scroll-item">
                                    <img src={image.src} alt={image.alt}/>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                <div className="icp-footer">
                    <span>@2024 王导导</span>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
                        京ICP备2023025405号-3
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Hero;
