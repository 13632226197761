import React from 'react';
import { Layout } from 'antd';
import './style.css';

const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  return (
    <AntHeader className="header">
      <div className="header-content">
        <div className="logo-container">
          <img 
            src={require("../../assets/images/ic_launcher.jpg")} 
            style={{width:'64px', height:'64px'}} 
            alt="DiDi" 
          />
          <span className="title">王导导</span>
        </div>
      </div>
    </AntHeader>
  );
};

export default Header; 